import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import { 
  LogoutOutlined 
} from '@ant-design/icons';
import { signOut } from 'redux/actions/Auth';

export const NavProfile = ({user, signOut}) => {
  console.log('>>> user', user)

  const profileImg = "/img/avatars/thumb-1.jpg";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <div className="mr-3">
            <h4 className="mb-0">{user.name}</h4>
            <span className="text-muted">{user.email}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item onClick={e => signOut()}>
            <span>
              <LogoutOutlined className="mr-3"/>
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar src={user.picture} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}


const mapStateToProps = ({ auth }) => ({user: auth.user});

export default connect(mapStateToProps, {signOut})(NavProfile)
