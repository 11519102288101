import {
  SET_NEWS_LIST_MESSAGE,
  FETCH_NEWS_LIST_START,
  FETCH_NEWS_LIST_SUCCESS,
  FETCH_NEWS_LIST_ERROR,
  SAVE_NEWS_START,
  SAVE_NEWS_SUCCESS,
  SAVE_NEWS_ERROR,
  SAVE_NEWS_CLEAR,
  FETCH_NEWS_CATEGORIES_START,
  FETCH_NEWS_CATEGORIES_SUCCESS,
  FETCH_NEWS_CATEGORIES_ERROR,
  DELETE_NEWS_START,
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_ERROR,
  DELETE_NEWS_CLEAR,
} from "../constants/News";

const initState = {
  loading: false,
  error: null,
  newsList: null,
  categories: null,
  savedItem: null,
  deletedItem: null,
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case SET_NEWS_LIST_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };

    case FETCH_NEWS_LIST_START:
      return {
        ...state,
        loading: true,
      };

    case FETCH_NEWS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        newsList: action.payload,
      };

    case FETCH_NEWS_LIST_ERROR:
      return {
        ...state,
        loading: false,
      };

    case SAVE_NEWS_START:
      return {
        ...state,
        loading: true,
        savedItem: null,
      };

    case SAVE_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        savedItem: action.payload,
      };

    case SAVE_NEWS_ERROR:
      return {
        ...state,
        loading: false,
        savedItem: null,
        error: action.payload,
      };

    case SAVE_NEWS_CLEAR:
      return {
        ...state,
        loading: false,
        savedItem: initState.savedItem,
      };

    case FETCH_NEWS_CATEGORIES_START:
      return {
        ...state,
        loading: true,
      };

    case FETCH_NEWS_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };

    case FETCH_NEWS_CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_NEWS_START:
      return {
        ...state,
        loading: true,
      };

    case DELETE_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        deletedItem: action.payload
      };

    case DELETE_NEWS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_NEWS_CLEAR:
      return {
        ...state,
        deletedItem: initState.deletedItem,
      };

    default:
      return state;
  }
};

export default auth;
