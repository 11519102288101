import {
  db,
  auth,
  googleAuthProvider,
  facebookAuthProvider,
} from "auth/FirebaseAuth";

const FirebaseService = {};

FirebaseService.signInEmailRequest = async (email, password) =>
  await auth
    .signInWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((err) => err);

FirebaseService.signInEmailRequest = async (email, password) =>
  await auth
    .signInWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((err) => err);

FirebaseService.signOutRequest = async () =>
  await auth
    .signOut()
    .then((user) => user)
    .catch((err) => err);

FirebaseService.signInGoogleRequest = async () =>
  await auth
    .signInWithPopup(googleAuthProvider)
    .then((user) => user)
    .catch((err) => err);

FirebaseService.signInFacebookRequest = async () =>
  await auth
    .signInWithPopup(facebookAuthProvider)
    .then((user) => user)
    .catch((err) => err);

FirebaseService.signUpEmailRequest = async (email, password) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((err) => err);

FirebaseService.getNews = () =>
  new Promise((resolve, reject) => {
    db.collection("news")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        resolve(data);
      })
      .catch(reject);
  });

FirebaseService.saveNews = (news) =>
  new Promise((resolve, reject) => {
    if (news.id) {
      db.collection("news")
        .doc(news.id)
        .set(
          {
            ...news,
            date: new Date(),
          },
          { merge: true }
        )
        .then((docRef) => {
          resolve({
            id: news.id,
            ...news,
          });
        })
        .catch(reject);
    } else {
      db.collection("news")
        .add({
          ...news,
          date: new Date(),
        })
        .then((docRef) => {
          resolve({
            id: docRef.id,
            ...news,
          });
        })
        .catch(reject);
    }
  });

FirebaseService.getCategories = () =>
  new Promise((resolve, reject) => {
    db.collection("categories")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        resolve(data);
      })
      .catch(reject);
  });

FirebaseService.deleteNewsById = (id) =>
  new Promise((resolve, reject) => {
    db.collection("news")
      .doc(id)
      .delete()
      .then(() => {
        resolve({
          id,
        });
      })
      .catch(reject);
  });

export default FirebaseService;
