import { HomeOutlined, ReadOutlined } from "@ant-design/icons";

const dashBoardNavTree = [
  {
    key: "home",
    path: "/app/pages",
    title: "home",
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "apps-ecommerce-news",
    path: "/app/news",
    title: "sidenav.apps.ecommerce.news",
    icon: ReadOutlined,
    breadcrumb: true,
    submenu: [],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
