export const SET_NEWS_LIST_MESSAGE = 'SET_NEWS_LIST_MESSAGE'; 

export const FETCH_NEWS_LIST_START = 'FETCH_NEWS_LIST_START'; 
export const FETCH_NEWS_LIST_SUCCESS = 'FETCH_NEWS_LIST_SUCCESS'; 
export const FETCH_NEWS_LIST_ERROR = 'FETCH_NEWS_LIST_ERROR'; 

export const SAVE_NEWS_START = 'SAVE_NEWS_START'; 
export const SAVE_NEWS_SUCCESS = 'SAVE_NEWS_SUCCESS'; 
export const SAVE_NEWS_ERROR = 'SAVE_NEWS_ERROR'; 
export const SAVE_NEWS_CLEAR = 'SAVE_NEWS_CLEAR'; 

export const FETCH_NEWS_CATEGORIES_START = 'FETCH_NEWS_CATEGORIES_START'; 
export const FETCH_NEWS_CATEGORIES_SUCCESS = 'FETCH_NEWS_CATEGORIES_SUCCESS'; 
export const FETCH_NEWS_CATEGORIES_ERROR = 'FETCH_NEWS_CATEGORIES_ERROR'; 

export const DELETE_NEWS_START = 'DELETE_NEWS_START'; 
export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS'; 
export const DELETE_NEWS_ERROR = 'DELETE_NEWS_ERROR'; 
export const DELETE_NEWS_CLEAR = 'DELETE_NEWS_CLEAR'; 